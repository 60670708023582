'use strict';

angular.module('mvpcarrefourApp')
  .controller('SidebarCatCtrl', function($scope, Category, socket) {
    let self = $scope;   

    Category.all.get(function(categories) {
      self.categories = categories;
      self.allCategory = _.filter(categories, function(category) {
        return category.children.length != 0;
      })
   
      socket.syncUpdates('category', self.categories);
    });
  });
